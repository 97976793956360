<template>
  <initServer :show.sync="visible" :close-on-press-escape="false" :show-close="false" @saveSuccess="saveCallback" />
</template>

<script>
/**
 * @desc 独立学校服务器配置页面
 */
// components
import initServer from '../../components/initServer';

export default {
  name: 'school-server-config',
  components: {
    initServer,
  },
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    saveCallback() {
      this.$router
        .push('/back-stage')
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>
